<template>
  <div class="overview">
    <b-container class="main-gradient light" fluid>
      <b-row>
        <b-col no-gutters>
          <b-row>
            <b-col class="chart-container">
              <income-expense-twelve-months
                :currency="'€'"
                :currencySymbol="'€'"
                :labels="labels"
                :dataObject="dataObject"
                :primaryLabel="'Income'"
                :secondaryLabel="'Expense'"
                :differenceLabel="'Difference'"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import incomeExpenseTwelveMonths from '@/components/d3charts/income-expense-twelve-months/index'

export default {
  components: {
    incomeExpenseTwelveMonths
  },
  data () {
    return {
      labels: [
        '2019-01',
        '2019-02',
        '2019-03',
        '2019-04',
        '2019-05',
        '2019-06',
        '2019-07',
        '2019-08',
        '2019-09',
        '2019-10',
        '2019-11',
        '2019-12'
      ],
      dataObject: {
        primaryDataset: [1000, 2000, 3333, 4444, 8055, 2345, 1780, 2340, 2000, 3333, 4444, 1050],
        secondaryDataset: [900, 1400, 1333, 3444, 2555, 2045, 1280, 2040, 1000, 1333, 3444, 2050]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart-container {
    padding-top: 50px;
  }
</style>
