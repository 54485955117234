<template>
  <div class="income-expense-month">
    <svg :width="width"
         :height="height">
      <linearGradient id="lineGradient" gradientUnits="userSpaceOnUse"
                      x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%"  stop-color="rgba(207, 139, 138, 0.6)" />
        <stop offset="100%" stop-color="rgba(225, 171, 170, 0.2)" />
      </linearGradient>
      <g :transform="`translate(${padding.left}, ${padding.top})`" class="chart-bars"></g>
      <g :transform="`translate(${padding.left}, ${padding.top})`" class="chart"></g>
      <Tooltip :svgWidth="width"
               :svgHeight="height"
               :obj="tooltipObj"
               :handleOverflowX="true"
               :handleOverflowY="false">
      </Tooltip>
    </svg>
  </div>
</template>

<script>
import Tooltip from '../d3-assets/Tooltip'
import patternify from '../d3-assets/patternify'

export default {
  props: [
    'currency',
    'currencySymbol',
    'dataObject', // dataset with primaryDataset and secondaryDataset properties. primary = bars, secondary = line
    'labels', // x-axis labels
    'primaryLabel', // tooltip label that indicates bars and corresponds to primaryDataset property in dataObject
    'secondaryLabel', // tooltip label that indicates line and corresponds to secondaryDataset property in dataObject
    'differenceLabel'
  ],
  components: {
    Tooltip
  },
  data () {
    return {
      width: 0,
      height: 550,
      transitionTime: 750,
      padding: {
        left: 110,
        right: 15,
        top: 35,
        bottom: 55
      },
      mehrHeight: 60,
      axisCircleSize: 4,
      capacityPercentage: 0.2,
      colors: {
        positive: '#98ccad',
        negative: '#e1abaa'
      },
      circleColor: '#e1abaa',
      darkColor: '#A5ADBA',
      tooltipObj: {
        visible: false
      },
      svg: null,
      chart: null
    }
  },
  computed: {
    tooltipData () {
      return this.labels.map((label, i) => {
        const primarySum = this.dataObject.primaryDataset[i]
        const secondarySum = this.dataObject.secondaryDataset[i]

        return { label, primarySum, secondarySum }
      })
    },
    chartHeight () {
      return this.height - this.padding.top - this.padding.bottom
    },
    chartWidth () {
      return this.width - this.padding.left - this.padding.right
    },
    xScale () {
      return this.$d3.scaleBand()
        .domain(this.labels)
        .range([0, this.chartWidth])
        .paddingInner([0.02])
        .paddingOuter([0.1])
    },
    yScale () {
      var min
      var max

      if (!this.dataObject) {
        min = 0
        max = 1
      } else {
        min = this.$d3.min(
          Object.keys(this.dataObject), key => this.$d3.min(this.dataObject[key])
        )
        max = this.$d3.max(
          Object.keys(this.dataObject), key => this.$d3.max(this.dataObject[key])
        )
      }

      return this.$d3.scaleLinear()
        .domain([Math.min(0, min), Math.max(0, max)])
        .range([this.chartHeight, 0])
    },
    lineGenerator () {
      return this.$d3.line()
        .x((d, i) => {
          if (i > 0) {
            return this.xScale(this.labels[i]) + this.xScale.bandwidth() / 2
          }
          return this.xScale(this.labels[i])
        })
        .y(d => this.yScale(d))
    },
    areaGenerator () {
      return this.$d3.area()
        .x((d, i) => {
          if (i > 0) {
            return this.xScale(this.labels[i]) + this.xScale.bandwidth() / 2
          }
          return this.xScale(this.labels[i])
        })
        .y0(this.yScale(0))
        .y1(d => this.yScale(d))
    }
  },
  methods: {
    thousandsFormat (value) {
      const locale = this.$d3.formatLocale({
        decimal: ',',
        thousands: ' ',
        grouping: [3]
      })
      return locale.format(',')(value)
    },
    buildTooltipHtml (d, i) {
      return `
        <div class="toolTip">
          <div class="year">
            <strong><span>${d.label}</span></strong>
          </div>
          <div class="d-flex justify-content-between mt-2">
            <div class="mr-3">
              <span class="tooltip-amount-span" style="background-color: #98ccad;"></span>
              <span class="ml-2 label-span">${this.primaryLabel}:</span>
            </div>
            <div class="ml-4">
              <span class="amount-span">${this.thousandsFormat(d.primarySum)}</span> ${this.currency}
            </div>
          </div>
          <div class="d-flex justify-content-between mt-2">
            <div class="mr-3">
              <span class="tooltip-expense-span"></span>
              <span class="ml-2 label-span">${this.secondaryLabel}:</span>
            </div>
            <div class="ml-4">
              <span class="amount-span">${this.thousandsFormat(d.secondarySum)}</span> ${this.currency}
            </div>
          </div>
          <hr class="tooltip-hr">
          <div class="d-flex justify-content-between mt-2">
            <div class="mr-3">
              <span class="ml-2 label-span">${this.differenceLabel}:</span>
            </div>
            <div class="ml-4">
              <span class="amount-span">${this.thousandsFormat(d.primarySum - d.secondarySum)}</span> ${this.currency}
            </div>
          </div>
        </div>`
    },
    rectmouseover (d, i) {
      const html = this.buildTooltipHtml(d, i)

      const x = this.xScale(d.label) + this.xScale.bandwidth() / 2 + this.padding.left
      const y = this.padding.top * 1.5

      this.tooltipObj = {
        x: x,
        y: y,
        html: html,
        visible: true
      }

      // current tick selection
      const xTick = this.chart
        .select(`#xTick-${d.label}`)

      // make circle bigger and filled white
      xTick.select('circle')
        .attr('fill', '#fff')
        .attr('stroke', '#1971ff')
        .attr('r', this.axisCircleSize + 2)

      // display hover rect
      this.chart
        .select('#hoverrect')
        .attr('x', this.xScale(d.label))
        .style('display', 'block')

      // make texts a little bit bigger
      xTick.selectAll('text')
        .attr('fill', '#fff')
        .attr('font-weight', 600)
    },
    rectmouseout (d, i) {
      // current tick selection
      const xTick = this.chart.select(`#xTick-${d.label}`)

      // make circle smaller
      xTick.select('circle')
        .attr('fill', '#1971ff')
        .attr('stroke', '#fff')
        .attr('r', this.axisCircleSize)

      // hide hover rect
      this.chart
        .select('#hoverrect').style('display', 'none')

      // make texts smaller
      xTick.selectAll('text')
        .attr('fill', this.darkColor).attr('font-weight', 400)

      // hide tooltip
      this.tooltipObj.visible = false
    },
    calcOpacity (d, i) {
      const length = this.labels.length
      const l = Math.round(length * this.capacityPercentage)
      const h = length - l

      const sc = this.$d3.scaleLinear().clamp(true)

      if (i <= l) {
        sc.domain([0, l]).range([0.25, 1])
        return sc(i)
      }

      if (i >= h) {
        sc.domain([h - 1, length - 1]).range([1, 0.25])
        return sc(i)
      }

      return 1
    },
    drawAxis () {
      const tickSize = this.chartHeight
      const xAxis = this.$d3.axisBottom(this.xScale).tickSize(-(tickSize))
      const yAxis = this.$d3.axisLeft(this.yScale).ticks(5).tickFormat(d => this.thousandsFormat(d) + ' ' + this.currencySymbol)

      // append xAxis
      patternify({
        container: this.chart,
        tag: 'g',
        selector: 'xAxis'
      })
        .attr('class', 'xAxis axis')
        .attr('transform', `translate(${0}, ${this.chartHeight})`)
        .call(xAxis)
        .style('font-size', 12)

      // append yAxis
      patternify({
        container: this.chart,
        tag: 'g',
        selector: 'yAxis'
      })
        .attr('class', 'yAxis axis')
        .call(yAxis)
    },
    drawChart () {
      var bars = patternify({
        tag: 'rect',
        selector: 'bar',
        container: this.chartBar,
        data: this.dataObject.primaryDataset
      })
        .attr('fill', d => d > 0 ? this.colors.positive : this.colors.negative)
        .attr('height', 0)
        .attr('width', this.xScale.bandwidth())
        .attr('x', (d, i) => this.xScale(this.labels[i]))
        .attr('y', this.yScale(0))
        .attr('opacity', (d, i) => this.calcOpacity(d, i))

      bars.transition().duration(this.transitionTime)
        .attr('height', d => Math.abs(this.yScale(0) - this.yScale(d)))
        .attr('y', d => {
          return d > 0 ? this.yScale(d) : this.yScale(0)
        })

      // draw area
      var area = patternify({
        tag: 'path',
        selector: 'area',
        container: this.chart,
        data: [this.dataObject.secondaryDataset]
      })
        .attr('d', d => {
          return this.areaGenerator(d.map(x => 0))
        })
        .attr('fill', 'url(#lineGradient)')

      // draw line
      var line = patternify({
        tag: 'path',
        selector: 'line',
        container: this.chart,
        data: [this.dataObject.secondaryDataset]
      })
        .attr('d', this.lineGenerator)
        .attr('fill', 'none')
        .attr('stroke', '#fff')
        .attr('stroke-width', 2)
        .attr('opacity', 0)

      area.transition().duration(this.transitionTime)
        .attr('d', this.areaGenerator)
        .on('end', () => {
          line.transition().duration(this.transitionTime / 2).attr('opacity', 1)
        })

      // hidden rectangles
      patternify({
        tag: 'rect',
        selector: 'hiddenrect',
        container: this.chart,
        data: this.tooltipData
      })
        .attr('x', d => this.xScale(d.label))
        .attr('y', 0)
        .attr('width', d => this.xScale.bandwidth())
        .attr('height', this.height)
        .attr('opacity', (d, i) => this.calcOpacity(d, i))
        .on('mouseover', (d, i) => this.rectmouseover(d, i))
        .on('mouseout', (d, i) => this.rectmouseout(d, i))

      // hover rectangle
      patternify({
        tag: 'rect',
        selector: 'hoverrect',
        container: this.chart
      })
        .attr('id', 'hoverrect')
        .attr('opacity', 0.35)
        .attr('fill', 'black')
        .attr('x', 0)
        .attr('y', -this.padding.top)
        .attr('width', d => this.xScale.bandwidth())
        .attr('height', this.height)
    },
    adjustAxis () {
      // let self = this
      const ticks = this.chart.select('g.xAxis')
        .selectAll('.tick')
        .attr('id', (d, i) => {
          return `xTick-${this.labels[i]}`
        })
        .attr('opacity', (d, i) => {
          return this.calcOpacity(d, i)
        })

      ticks.select('line')
        .attr('stroke', this.darkColor)
        .attr('stroke-width', 0.5)
        .attr('stroke-dasharray', '3 1.5')

      // reposition texts within the axis
      ticks.select('text')
        .attr('dy', '2em')
        .attr('fill', this.darkColor)
        .attr('font-family', 'Roboto')

      // append circles
      patternify({
        container: ticks,
        tag: 'circle',
        selector: 'tickCircle'
      })
        .attr('r', this.axisCircleSize)
        .attr('fill', this.circleColor)
        .attr('stroke', '#fff')
        .attr('stroke-width', 1)

      // adjust xAxis
      const yTicks = this.chart.select('g.yAxis')
        .selectAll('.tick')

      // reposition lines
      yTicks.select('line')
        .attr('x1', (d, i) => {
          if (i) {
            return -7
          }
          return 0
        })
        .attr('x2', (d, i) => {
          if (i) {
            if (d === 0) {
              return this.chartWidth
            }
            return 4
          }
          return 0
        })
        .attr('stroke', d => {
          if (d === 0) {
            return '#ffffff'
          }
          return '#A5ADBA'
        })
        .attr('stroke-width', 1.5)

      yTicks.select('text')
        .attr('x', -24)
        .attr('font-family', 'Roboto')

      // append circles
      patternify({
        container: yTicks,
        tag: 'circle',
        selector: 'tickCircle'
      })
        .attr('r', this.axisCircleSize)
        .attr('cx', -10)
        .attr('fill', '#6D7290')
        .attr('stroke', '#ffffff')
        .attr('stroke-width', 1)
    },
    setWidth () {
      this.width = this.$el.offsetWidth
    },
    onResize () {
      this.setWidth()
    },
    draw () {
      this.drawChart()
      this.drawAxis()
      this.adjustAxis()
    }
  },
  watch: {
    dataObject (val) {
      if (val) {
        this.draw()
      }
    }
  },
  mounted () {
    this.svg = this.$d3.select(this.$el).select('svg')
    this.chart = this.svg.select('g.chart')
    this.chartBar = this.svg.select('g.chart-bars')

    this.$d3.select(window).on('resize', () => {
      this.onResize()
      if (this.dataObject) {
        this.draw()
      }
    })
    this.onResize()

    if (this.dataObject) {
      this.draw()
    }
  }
}
</script>

<style lang="scss">
  @import './index.scss';
</style>
